html {
  font-size: 62.5%;
  box-sizing: border-box;

  @include print {
    font-size: 50%;
  }
}

*,
*::before,
*::after { box-sizing: inherit; }

body {
  min-height: 100%;
  margin: 0;
  padding: 0;

  font-family: 'Space Mono', monospace;
  font-size: $font-size;
  line-height: $line-height;
  font-variant-ligatures: none;

  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;

  background: $background;

  @include print {
    background: transparent;
  }
}

::selection  {
  background: $color--accent;
  color: $black;
}

img {
  border: 0;
  -ms-interpolation-mode: bicubic;
  vertical-align: bottom; /* Suppress the space beneath the baseline */
}

a {
  @include easing(300ms, border-bottom-color);
  color: $color--accent;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

a:not([href]),
a:not([href]):hover {
  color: inherit;
  text-decoration: none;
}

h1, h2, h3, h3, h4, h5, h6, p, ul, ol {
  &:not(:last-child) {
    margin-bottom: 1em;
  }
}

.has-tippy {
  @include easing(300ms, border-color);
  position: relative;
  cursor: help;
  border-bottom: 1px dashed $black;

  // Filter out <img>
  &[src] {
    border: 0;
  }
}

.has-photoswipe {
  cursor: pointer;
  cursor: zoom-in;
}

main {
  padding: $gutter;
}

button,
input[type='submit'] {
  @include reset-button;
  position: relative;
  cursor: pointer;

  color: var(--color, $white);
  background: var(--background, $black);
  border: 1px solid;
  font-size: $font-size--sm;

  padding: $button--padding-box;
  height: $button--height;
  min-width: $button--height;

  &:active {
    transform: translate(1px, 1px);
  }
}

.headbar {
  @include no-select;
  z-index: 2;
  position: sticky;
  top: 0;
  width: 100vw;
  background: $black;
  color: $white;
  padding: $gutter;
  font-size: $font-size--sm;

  display: flex;
  justify-content: space-between;
  overflow: hidden;

  @include print {
    display: none;
  }

  &__user,
  &__breadcrumb {
    display: flex;
    overflow: hidden;
    white-space: nowrap;

    h1, span {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  @include desktop {
    &__user > *:not(:first-child)::before,
    &__breadcrumb > *:not(:first-child)::before {
      content: '\00a0/';
      color: $gray;
      font-weight: normal;
    }

    &__breadcrumb + &__user {
      margin-left: 2ch;
    }
  }

  @include devices (mobile, tablet) {
    &__breadcrumb {
      span {
        &:not(:last-of-type) {
          display: none;
        }

        a {
          // NOTE: this is a hack to hide the content
          font-size: 0;
          line-height: 0;
          letter-spacing: -0.1rem;
          border: 0;
          &::before {
            display: inline-block;
            font-size: $font-size * 2;
            vertical-align: -$font-size * 0.4;
            content: '←\00a0';
          }
        }
      }
    }

    &__user {
      display: none;
    }
  }

  a {
    @include easing(300ms, color);
    color: inherit;
    &:not(:hover):not(:focus) {
      color: $gray;
    }
  }

  h1 {
    font-size: inherit;
    margin: 0;
  }
}

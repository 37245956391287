.table {
  border: 1px solid;
  width: 100%;

  thead {
    @include no-select;
    text-align: left;
    font-size: $font-size--sm;

    th[data-sortable]  {
      cursor: pointer;
    }

    // Avoid jumps in layout when setting data-sort
    th[data-sortable]::after {
      content: '\2191';
      margin-left: 1ch;
      visibility: hidden;
    }

    // Only sortable columns show an arrow
    th[data-sortable][data-sort]::after { visibility: visible; }
    th[data-sortable][data-sort='asc']::after { content: '\2191'; }
    th[data-sortable][data-sort='desc']::after { content: '\2193'; }
  }

  tbody {}

  tbody tr {
    @include easing(300ms, background);
  }

  tbody tr:hover {
    background: $white;
  }

  tbody tr[data-href]:hover {
    cursor: pointer;
    td > * {
      border-color: currentColor;
    }
  }

  td, th {
    padding: $gutter;
    border-bottom: 1px solid;

    &.col-sm {
      width: 5ch;
    }
  }

  // Disable table layout
  @include devices (mobile, tablet) {
    & {
      border: 0;
      width: 100vw;
      margin: -$gutter;
    }

    // thead { display: none; }
    tr, td { display: block; }

    tbody {
      display: flex;
      flex-direction: column;
      width: calc(100vw - #{$gutter * 10});
    }

    thead {
      position: sticky;
      top: $headbar--height;
      border: 0;
      background: $background;
    }

    thead, tr {
      width: 100vw;
      display: flex;
      flex-wrap: wrap;
    }

    td, th {
      flex: 1;

      &:not(:first-child):not(:last-child) {
        display: none;
      }

      &:last-child {
        align-self: flex-end;
        text-align: right;
      }
    }
  }
}

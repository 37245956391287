@font-face {
  font-family: 'Space Mono';
  font-display: auto;
  src: url('../fonts/spacemono-regular.woff2') format('woff2'),
       url('../fonts/spacemono-regular.woff') format('woff'),
       url('../fonts/spacemono-regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Space Mono';
  font-display: auto;
  src: url('../fonts/spacemono-bold.woff2') format('woff2'),
       url('../fonts/spacemono-bold.woff') format('woff'),
       url('../fonts/spacemono-bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Space Grotesk';
  font-display: auto;
  src: url('../fonts/spacegrotesk-regular.woff2') format('woff2'),
       url('../fonts/spacegrotesk-regular.woff') format('woff'),
       url('../fonts/spacegrotesk-regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Space Grotesk';
  font-display: auto;
  src: url('../fonts/spacegrotesk-bold.woff2') format('woff2'),
       url('../fonts/spacegrotesk-bold.woff') format('woff'),
       url('../fonts/spacegrotesk-bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@mixin html-no-scroll {
  html {
    height: 100%;
    overflow: hidden;
    body {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      max-width: 100vw;
      overflow-x: none;
      overflow-y: none;
    }
  }
}

@mixin invisible-scroll ($direction: x) {
  @if $direction == x {
    overflow-x: scroll;
    overflow-y: hidden;
  } @else if $direction == y {
    overflow-x: hidden;
    overflow-y: scroll;
  } @else {
    overflow-x: scroll;
    overflow-y: scroll;
  }

  scrollbar-width: none;
  scrollbar-height: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    height: 0;
    background: transparent;
  }
}

@mixin debug ($color: red) {
  outline: 1px solid $color;
}

@mixin easing ($duration: 100ms, $property: all, $will-change: false) {
  transition: $property $duration cubic-bezier(0.25, 0.1, 0.25, 1);
  @if $will-change {
    will-change: $property;
  }
}

@mixin ratio ($ratio: 1) {
  height: auto;
  &::before {
    float: left;
    padding-top: 100% / $ratio;
    content: '';
  }
}

@mixin checkerboard ($size: 48px, $color: #808080, $background: white) {
  background-color: $background;
  background-image:
    linear-gradient(45deg, $color 25%, transparent 25%),
    linear-gradient(-45deg, $color 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, $color 75%),
    linear-gradient(-45deg, transparent 75%, $color 75%);
  background-position: 0 0, 0 #{$size / 2}, #{$size / 2} -#{$size / 2}, -#{$size / 2} 0;
  background-size: $size $size;
}

@mixin reset-button {
  width: auto;
  margin: 0;
  padding: 0;
  overflow: visible;

  color: inherit;
  font: inherit;
  line-height: inherit;

  background: transparent;
  border: none;

  appearance: none;

  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &::-moz-focus-inner {
    padding: 0;
    border: 0;
  }

  &:focus { outline: none; }
}

@mixin no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

@mixin no-smooth {
  image-rendering: optimizeSpeed;
  image-rendering: crisp-edges;
  image-rendering: pixelated;
}

@mixin loader ($size: 5rem, $thickness: 1rem, $foreground: $black, $background: transparent, $speed: 1s) {
  @keyframes loader-spin {
    0% { transform: translate(-50%, -50%) rotate(0deg); }
    100% { transform: translate(-50%, -50%) rotate(360deg); }
  }

  position: absolute;
  top: 50%;
  left: 50%;

  width: $size;
  height: $size;

  border: $thickness solid $background;
  border-top: $thickness solid $foreground;
  border-left: $thickness solid $foreground;
  border-radius: 50%;

  animation: loader-spin $speed linear infinite;

  content: '';
  pointer-events: none;
}

.pswp {
  &__ui {
    -webkit-font-smoothing: unset;
  }

  &__caption {
    @include no-select;
    &__center {
      padding: $gutter;
      font-size: $font-size;
      max-width: 70ch;
      color: $white;
      footer {
        font-size: $font-size--sm;
        text-align: right;
      }
    }
  }
}

.project-data {
  margin-bottom: $gutter;

  &__label {
    @include no-select;
    font-weight: bold;
    font-size: $font-size--sm;
    margin-bottom: 0.5em;
  }

  &--text {}
  &--image {}
  &--file {}

  iframe {
    margin-top: 0.5em;
    outline: 1px solid;
  }
}

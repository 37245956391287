.project-rater {
  @include no-select;

  @include print {
    display: none;
  }

  &[disabled] {
    pointer-events: none;
    input[type='submit'] {
      display: none;
    }
  }

  fieldset {
    margin-bottom: $gutter;
    font-size: $font-size--sm;
  }

  fieldset legend {
    cursor: default;
    position: relative;
    padding-bottom: 4px;
  }

  &__average {
    color: $color--accent;
    &::before {
      content: ':';
      color: $black;
    }
  }

  &__stars {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    padding: $button--padding-box;
    padding-left: 0;
  }

  &__stars input {
    display: none;
  }

  &__stars label {
    @include easing(300ms, color);
    position: relative;
    cursor: pointer;
    font-size: $gutter;
    padding-right: $gutter / 2;
    color: rgba($black, 0.1);

    // Increase hitbox
    &::before {
      content: '';
      position: absolute;
      top: -50%;
      left: 0;
      bottom: -50%;
      right: 0;
    }

    &::after {
      display: block;
      content: '⬤'; // '★';
    }
  }

  // Selected stars
  &__stars > input:checked ~ label {
    color: $color--accent;
  }

  // Selected stars being modified
  &__stars:hover > input:checked ~ label {
    color: rgba($color--accent, 0.3);
  }

  // Stars hovered after current max
  &__stars:not(:checked) > label:hover,
  &__stars:not(:checked) > label:hover ~ label {
    color: $color--accent;
  }

  // Stars hovered before current max
  &__stars > input:checked + label:hover,
  &__stars > input:checked + label:hover ~ label,
  &__stars > input:checked ~ label:hover,
  &__stars > input:checked ~ label:hover ~ label,
  &__stars > label:hover ~ input:checked ~ label {
    color: $color--accent;
  }

  input[type='submit'] {
    border: 0;
  }
}

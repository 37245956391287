.project {
  article {
    max-width: calc(100vw - #{$gutter * 2});
    display: grid;
    padding-top: $gutter;

    @include desktop {
      grid-template-columns: 1fr minmax(200px, 25vw);
      grid-gap: $gutter;
    }

    @include print {
      &::before {
        content: attr(data-parent);
        font-size: $font-size--lg;
        font-weight: bold;
        padding-bottom: $gutter;
      }
    }
  }

  &__header {
    margin-bottom: $gutter * 2;
  }

  &__header &__title {
    font-size: $font-size--xlg;
  }

  &__header &__datas {
    column-count: 2;
    @include mobile {
      column-count: 1;
    }
  }

  // Prevent spanning a data on two columns
  &__data > * {
    display: inline-block;
  }

  &__content {
    max-width: 80ch;
    margin: 0 auto;

    img {
      max-width: 100%;
    }
  }

  &__content &__excerpt,
  &__content &__text {
    font-family: 'Space Grotesk';
    font-size: $font-size--lg;
    line-height: 1.25;
  }

  .project-data__label {
    margin-bottom: 1em;
    line-height: 1.25;
  }

  &__gallery {
    position: relative;
    margin: $gutter #{-()} $gutter;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: $gutter;

    @include print {
      display: block;
      page-break-before: always;
      grid-template-columns: repeat(1, 1fr);
      .project-data__label { display: none; }

      .project-data--image,
      .project-data--file {
        page-break-inside: avoid;
        display: grid;
        place-items: center;
        outline: 1px solid;
        margin-bottom: $gutter;
      }

      figure {
        page-break-inside: avoid;
      }
    }

    .project-data--image {
      display: grid;
      margin: 0;
      line-height: 0;
    }

    .project-data--file {
      word-break: break-all;
    }

    figure {}
    figcaption {
      display: none;

      @include print {
        padding: $gutter;
        line-height: 1.25;
        display: block;

        footer::before {
          content: 'Copyright';
          font-weight: bold;
          display: block;
          font-size: $font-size--sm;
          margin-bottom: 0.5em;
        }
      }
    }
  }

  aside {
    width: 100%;
    max-width: 80ch;
    margin: 0 auto;

    @include desktop {
      position: sticky;
      top: $gutter * 6;
      padding: 0 $gutter / 2;
      height: min-content;
      max-height: 80vh;
    }
  }

  &__anonymous {
    display: grid;
    padding-top: $gutter;
    background: $color--accent;
    color: $white;
    margin: -$gutter;
    margin-top: $gutter * 2;
    padding: $gutter;

    @include print {
      page-break-before: always;
      padding-top: $gutter;
      background: none;
      color: inherit;

      h2 {
        font-size: 0;
        &::before {
          content: 'Candidat';
          font-weight: bold;
          font-size: $font-size--xlg;
          margin-bottom: $gutter * 2;
        }
      }
    }

    @include desktop {
      grid-template-columns: 1fr minmax(200px, 25vw);
      grid-row-gap: $gutter;
    }

    h2 {
      margin-bottom: $gutter * 2;
    }
  }

  &__anonymous &__datas {
    @include easing(300ms, opacity);
    @include desktop {
      column-count: 2;
      column-gap: $gutter;
    }

    &-wrapper {
      margin: 0 auto;
      width: 100%;
      max-width: 80ch;
    }

    img {
      max-width: 60%;
    }

    a {
      color: inherit;
    }
  }

  &__anonymous:not(:hover) &__datas {
    opacity: 0.1;
    @include print {
      opacity: 1;
    }
  }
}

@import '~tippy.js/dist/tippy.css';
@import '~photoswipe/dist/photoswipe';
@import '~photoswipe/dist/default-skin/default-skin';

@import 'sass/reset';
@import 'sass/fonts';
@import 'sass/helpers';
@import 'sass/devices';

$black: #000000;
$white: #FFFFFF;
$gray: #808080;

$color--accent: #005b3c;
$color--alert: #dd0028;

$background: #EEE;

$gutter: 2rem;
$icon-size: 1.3rem;

$font-size: 1.8rem;
$font-size--sm: 1.4rem;
$font-size--lg: 2.0rem;
$font-size--xlg: 4rem;
$line-height: 1.1;

$headbar--height: 5.8rem;
$button--height: 4.5rem;
$button--padding-box: 0.8rem 1rem;

.tippy {
  &-box { border-radius: 0; }
  &-content {
    @include no-select;
    transform: translateY(-1px);
    max-width: 30ch;
  }
}

@import 'sass/base';

@import 'components/Headbar';
@import 'components/Login';
@import 'components/PhotoSwipe';
@import 'components/Project';
@import 'components/ProjectData';
@import 'components/ProjectNotes';
@import 'components/ProjectRater';
@import 'components/Projects';
@import 'components/Table';

.project-notes {
  @include print {
    display: none;
  }

  &__total {
    font-size: $font-size--xlg;
    font-weight: bold;
    color: $color--accent;
    margin-bottom: $gutter;

    &::before {
      content: 'Moyenne';
      font-size: $font-size--sm;
      color: $black;
      display: block;
      font-weight: normal;
    }
  }

  &__note {
    display: flex;
    font-size: $font-size--sm;
    justify-content: space-between;

    padding-top: $gutter / 2;
    border-top: 1px dotted $gray;
    margin-top: $gutter / 2;
  }

  &__note-label {
    max-width: 40ch;
  }

  &__note-value {
    padding-left: 1ch;
    font-weight: bold;
    color: $color--accent;
    float: right;
    align-self: flex-end;
  }
}
